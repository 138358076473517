import { metricsDataProviderMixinFactory } from '@hypefactors/shared/js/components/charts/MetricsDataProviderMixin'

export default {
  name: 'NewsroomFactsDataProvider',

  mixins: [metricsDataProviderMixinFactory({ watchFor: 'appliedFilters', vuexModule: 'newsroom/facts' })],

  props: {
    newsroomId: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      chartData: {
        stories: {},
        newsroom: {}
      }
    }
  },

  methods: {
    fetchData () {
      return this.$api.get(`newsrooms/${this.newsroomId}/analytics`, {
        params: {
          ...this.appliedFilters
        }
      })
        .then((response) => {
          this.chartData = response.data.data
        })
    }
  },

  render (h) {
    return h('div', [this.$scopedSlots.default({
      storyData: this.chartData.stories,
      newsroomData: this.chartData.newsroom,
      isLoading: this.isLoading
    })])
  }
}
