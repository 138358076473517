<template>
  <div
    class="responsive-table"
    data-testid="NewsroomFactsTopStories"
  >
    <table class="table is-full is-bordered">
      <thead class="has-background-grey-lighter is-size-6 no-hover">
        <tr>
          <td class="is-narrow">
            {{ $t('components.newsroom_facts_top_stories.rank') }}
          </td>
          <td class="is-narrow">
            {{ $t('components.newsroom_facts_top_stories.views') }}
          </td>
          <td>{{ $t('components.newsroom_facts_top_stories.story') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(story, index) in chartData"
          :key="index"
        >
          <td>{{ index + 1 }}</td>
          <td class="has-text-right">
            {{ story.views }}
          </td>
          <td>
            <a
              :href="$getUrl(story.url, 'hypenews', false)"
              class="has-text-primary"
              target="_blank"
            >
              {{ story.headline }}
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
/**
 * @module NewsroomFactsTopStories
 */
export default {
  name: 'NewsroomFactsTopStories',
  props: {
    chartData: {
      type: [Object, Array],
      default: () => ({})
    }
  }
}
</script>
