<template>
  <base-facts-summary-totals-section :chart-data="chartData">
    <data-card
      v-if="mostVisitedLink"
      :title="$t('components.newsroom_facts_summary_totals.most_visited_link')"
      size="medium"
      class="SquareTopStory"
    >
      <a
        :href="$getUrl(mostVisitedLink.url, 'hypenews', false)"
        class="has-text-primary is-size-5 align-middle"
        target="_blank"
      >
        {{ mostVisitedLink.headline }}
      </a>
      <template slot="footer">
        <i18n
          tag="div"
          path="components.newsroom_facts_summary_totals.views"
          class="has-text-weight-semibold"
        >
          <span slot="value">{{ mostVisitedLink.views }}</span>
        </i18n>
      </template>
    </data-card>
  </base-facts-summary-totals-section>
</template>

<script>

import BaseFactsSummaryTotalsSection from '@/components/stories/BaseFactsSummaryTotalsSection'

/**
 * @module NewsroomFactsSummaryTotalsSection
 */
export default {
  name: 'NewsroomFactsSummaryTotalsSection',
  components: { BaseFactsSummaryTotalsSection },
  props: {
    chartData: {
      type: [Object, Array],
      required: true
    },
    storyData: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    mostVisitedLink () {
      return this.storyData.length ? this.storyData[0] : undefined
    }
  }
}
</script>
