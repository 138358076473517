<template>
  <div class="NewsroomFacts">
    <newsroom-facts-data-provider
      :fetch-when="ready"
      :newsroom-id="newsroomId"
    >
      <div v-loading="isLoading" slot-scope="{ newsroomData, storyData, isLoading }">
        <template v-if="newsroomData.total_views < 50 && !$store.getters.isStaffMember">
          <div class="m-t-xl is-flex is-aligned-center">
            <div class="message is-warning">
              <div class="message-body">
                {{ $t('pages.newsroom_facts.crunching_data') }}
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <banded-section
            :title="$t('pages.newsroom_facts.engagements.sidebar_title')"
            :collapsable="false"
          >
            <newsroom-facts-filters @ready="ready = true" />
            <newsroom-facts-summary-totals-section :chart-data="newsroomData" :story-data="storyData" />
          </banded-section>
          <banded-section
            :title="$t('pages.newsroom_facts.device_distribution.sidebar_title')"
            :collapsable="false"
          >
            <newsroom-facts-device-distribution
              :total="newsroomData.total_views"
              :chart-data="newsroomData.pageviews_by_device"
            />
          </banded-section>
          <banded-section
            :title="$t('pages.newsroom_facts.top_stories.sidebar_title')"
            :collapsable="false"
          >
            <newsroom-facts-top-stories :chart-data="storyData" />
          </banded-section>
          <banded-section
            :title="$t('pages.newsroom_facts.top_sources.sidebar_title')"
            :collapsable="false"
          >
            <newsroom-facts-top-sources :chart-data="newsroomData.pageviews_by_source" />
          </banded-section>
        </template>
      </div>
    </newsroom-facts-data-provider>
  </div>
</template>

<script>
import NewsroomFactsFilters from '@/components/stories/NewsroomFactsFilters'
import NewsroomFactsDataProvider from '@/components/stories/NewsroomFactsDataProvider'
import NewsroomFactsTopStories from '@/components/stories/NewsroomFactsTopStories'
import NewsroomFactsTopSources from '@/components/stories/NewsroomFactsTopSources'
import NewsroomFactsDeviceDistribution from '@/components/stories/NewsroomFactsDeviceDistribution'
import NewsroomFactsSummaryTotalsSection from '@/components/stories/NewsroomFactsSummaryTotalsSection'

/**
 * @module NewsroomFacts
 */
export default {
  name: 'NewsroomFacts',
  components: {
    NewsroomFactsSummaryTotalsSection,
    NewsroomFactsDeviceDistribution,
    NewsroomFactsTopSources,
    NewsroomFactsTopStories,
    NewsroomFactsDataProvider,
    NewsroomFactsFilters
  },
  props: {
    newsroomId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      ready: false
    }
  }
}
</script>
